
import React from 'react'

const Productos: React.FC = (): JSX.Element => {
	/** Hooks */
	return (
		<div>
			<svg
				width="100%"
				height={'100%'}
				viewBox="0 0 1920 975"
				preserveAspectRatio="none">
				<defs>
					<style>
						{
							'.a-products{fill:#fff;stroke:#707070;}.b-products{fill:#e7002a;}.c-products,.e-products{fill:#001f5f;}.d-products{fill:none;}.e-products{font-size:100px;font-family:"Kiona-Bold";font-weight:700;}.f-products{fill:url(#a-products);}.g-products{fill:#182856;font-size:24px;font-family:Silka-SemiBold, Silka;font-weight:600;}.h-products{fill:url(#d-products);}.i-products{fill:url(#g-products);}.j-products{fill:url(#j-products);}.k-products{fill:url(#m-products);}.l-products{fill:url(#p-products);}.m-products{fill:url(#s-products);}.n-products{fill:url(#v-products);}.o-products{fill:url(#y-products);}.p-products{fill:url(#ab-products);}.q-products{stroke:none;}.r-products{filter:url(#ac-products);}.s-products{filter:url(#z-products);}.t-products{filter:url(#w-products);}.u-products{filter:url(#t-products);}.v-products{filter:url(#q-products);}.w-products{filter:url(#n-products);}.x-products{filter:url(#k-products);}.y-products{filter:url(#h-products);}.z-products{filter:url(#e-products);}.aa-products{filter:url(#b-products);}'
						}
					</style>
					<pattern
						id="a-products"
						preserveAspectRatio="xMidYMid slice"
						width="100%"
						height="100%"
						viewBox="0 0 6000 4000">
						<image
							width={6000}
							height={4000}
							xlinkHref="/images/productos.jpg"
						/>
					</pattern>
					<filter
						id="b-products"
						x={173.348}
						y={237.473}
						width={313.032}
						height={217.093}
						filterUnits="userSpaceOnUse">
						<feOffset dy={3} />
						<feGaussianBlur stdDeviation={3} result="c-products" />
						<feFlood floodOpacity={0.6} />
						<feComposite operator="in" in2="c-products" />
						<feComposite in="SourceGraphic" />
					</filter>
					<pattern
						id="d-products"
						width={1}
						height={1}
						viewBox="1.561 0 255.371 172.329">
						<image
							preserveAspectRatio="xMidYMid slice"
							width={298.639}
							height={199.093}
							xlinkHref="/images/product01.jpg"
						/>
					</pattern>
					<filter
						id="e-products"
						x={173.348}
						y={617.344}
						width={313.032}
						height={217.093}
						filterUnits="userSpaceOnUse">
						<feOffset dy={3} />
						<feGaussianBlur stdDeviation={3} result="f-products" />
						<feFlood floodOpacity={0.6} />
						<feComposite operator="in" in2="f-products" />
						<feComposite in="SourceGraphic" />
					</filter>
					<pattern
						id="g-products"
						width={1}
						height={1}
						viewBox="0 56.975 319.461 199.814">
						<image
							preserveAspectRatio="xMidYMid slice"
							width={319.461}
							height={479.192}
							xlinkHref="/images/product02.jpg"
						/>
					</pattern>
					<filter
						id="h-products"
						x={794.544}
						y={237.474}
						width={337.462}
						height={217.814}
						filterUnits="userSpaceOnUse">
						<feOffset dy={3} />
						<feGaussianBlur stdDeviation={3} result="i-products" />
						<feFlood floodOpacity={0.6} />
						<feComposite operator="in" in2="i-products" />
						<feComposite in="SourceGraphic" />
					</filter>
					<pattern
						id="j-products"
						width={1}
						height={1}
						viewBox="0 239.596 319.461 199.814">
						<image
							preserveAspectRatio="xMidYMid slice"
							width={319.461}
							height={479.192}
							xlinkHref="/images/products03.jpg"
						/>
					</pattern>
					<filter
						id="k-products"
						x={794.543}
						y={616.622}
						width={337.461}
						height={217.814}
						filterUnits="userSpaceOnUse">
						<feOffset dy={3} />
						<feGaussianBlur stdDeviation={3} result="l-products" />
						<feFlood floodOpacity={0.6} />
						<feComposite operator="in" in2="l-products" />
						<feComposite in="SourceGraphic" />
					</filter>
					<pattern
						id="m-products"
						width={1}
						height={1}
						viewBox="9.031 11.001 262.428 177.091">
						<image
							preserveAspectRatio="xMidYMid slice"
							width={298.563}
							height={199.093}
							xlinkHref="/images/product04.jpg"
						/>
					</pattern>
					<filter
						id="n-products"
						x={1440.168}
						y={237.473}
						width={313.032}
						height={217.093}
						filterUnits="userSpaceOnUse">
						<feOffset dy={3} />
						<feGaussianBlur stdDeviation={3} result="o-products" />
						<feFlood floodOpacity={0.6} />
						<feComposite operator="in" in2="o-products" />
						<feComposite in="SourceGraphic" />
					</filter>
					<pattern
						id="p-products"
						width={1}
						height={1}
						viewBox="112.699 34.927 191.518 129.239">
						<image
							preserveAspectRatio="xMidYMid slice"
							width={375.765}
							height={199.093}
							xlinkHref="/images/products05.jpg"
						/>
					</pattern>
					<filter
						id="q-products"
						x={1440.168}
						y={617.344}
						width={313.032}
						height={217.093}
						filterUnits="userSpaceOnUse">
						<feOffset dy={3} />
						<feGaussianBlur stdDeviation={3} result="r-products" />
						<feFlood floodOpacity={0.6} />
						<feComposite operator="in" in2="r-products" />
						<feComposite in="SourceGraphic" />
					</filter>
					<pattern
						id="s-products"
						preserveAspectRatio="xMidYMid slice"
						width="100%"
						height="100%"
						viewBox="0 0 1920 1280">
						<image
							width={1920}
							height={1280}
							xlinkHref="/images/product1.jpg"
						/>
					</pattern>
					<filter
						id="t-products"
						x={477.837}
						y={282.804}
						width={337.461}
						height={217.814}
						filterUnits="userSpaceOnUse">
						<feOffset dy={3} />
						<feGaussianBlur stdDeviation={3} result="u-products" />
						<feFlood floodOpacity={0.6} />
						<feComposite operator="in" in2="u-products" />
						<feComposite in="SourceGraphic" />
					</filter>
					<pattern
						id="v-products"
						width={1}
						height={1}
						viewBox="47.904 11.354 283.156 177.105">
						<image
							preserveAspectRatio="xMidYMid slice"
							width={355.224}
							height={199.814}
							xlinkHref="/images/produ2.jpg"
						/>
					</pattern>
					<filter
						id="w-products"
						x={477.839}
						y={571.292}
						width={337.461}
						height={217.814}
						filterUnits="userSpaceOnUse">
						<feOffset dy={3} />
						<feGaussianBlur stdDeviation={3} result="x-products" />
						<feFlood floodOpacity={0.6} />
						<feComposite operator="in" in2="x-products" />
						<feComposite in="SourceGraphic" />
					</filter>
					<pattern
						id="y-products"
						width={1}
						height={1}
						viewBox="105.82 0 245.807 153.745">
						<image
							preserveAspectRatio="xMidYMid slice"
							width={355.224}
							height={199.814}
							xlinkHref="/images/huesos.png"
						/>
					</pattern>
					<filter
						id="z-products"
						x={1111}
						y={282.804}
						width={337.461}
						height={217.814}
						filterUnits="userSpaceOnUse">
						<feOffset dy={3} />
						<feGaussianBlur stdDeviation={3} result="aa-products" />
						<feFlood floodOpacity={0.6} />
						<feComposite operator="in" in2="aa-products" />
						<feComposite in="SourceGraphic" />
					</filter>
					<pattern
						id="ab-products"
						preserveAspectRatio="xMidYMid slice"
						width="100%"
						height="100%"
						viewBox="0 0 8000 5334">
						<image
							width={8000}
							height={5334}
							xlinkHref="/images/pie.jpg"
						/>
					</pattern>
					<filter
						id="ac-products"
						x={1111.249}
						y={571.292}
						width={337.461}
						height={217.814}
						filterUnits="userSpaceOnUse">
						<feOffset dy={3} />
						<feGaussianBlur stdDeviation={3} result="ad-products" />
						<feFlood floodOpacity={0.6} />
						<feComposite operator="in" in2="ad-products" />
						<feComposite in="SourceGraphic" />
					</filter>
				</defs>
				<g className="a-products" transform="translate(3 1.188)">
					<rect className="q-products" width={1920} height={975} />
					<rect
						className="d-products"
						x={0.5}
						y={0.5}
						width={1919}
						height={975}
					/>
				</g>
				<path
					className="b-products"
					d="M496.245,701.064q.371-1.148.764-2.278c6.577-19.056,17.159-35.2,30.695-46.787,14.073-12.049,30.45-18.417,47.364-18.417H692v.04h71.037c27.772,0,53.171-17.259,68.8-45.44h1.02a100.13,100.13,0,0,1-22.458,28.124c-14.073,12.05-30.45,18.418-47.364,18.418H646.105v-.04H575.068c-34.233,0-64.863,26.222-78.034,66.8l-.025.079-21.981,67.751v-2.859Z"
					transform="translate(1090.136 -46.985)"
				/>
				<path
					className="b-products"
					d="M348.428,409.432q-.748-1.81-1.542-3.589A172.23,172.23,0,0,0,189.435,303.1H0v1.8H46.146v-.063H189.435A170.074,170.074,0,0,1,346.835,410.1l.051.125,44.338,106.756v-4.5Z"
					transform="translate(0 -114.601)"
				/>
				<path
					className="c-products"
					d="M1925.864,98.615A131.184,131.184,0,0,0,1825.079,51.38H1645.036v.048H1535.662A129.636,129.636,0,0,1,1432.106,0h-1.612a131.443,131.443,0,0,0,32.243,30.6,130.739,130.739,0,0,0,72.925,22.152H1715.7V52.7h109.374a129.628,129.628,0,0,1,100.785,47.9Z"
					transform="translate(-2.864)"
				/>
				<path
					className="c-products"
					d="M753.546,612.541q.431,1.612.889,3.2c7.646,26.758,19.948,49.422,35.684,65.7,16.36,16.919,35.4,25.862,55.063,25.862H981.125v-.056h82.584c32.286,0,61.814,24.235,79.986,63.808h1.186c-7.011-15.463-15.819-28.85-26.109-39.492-16.36-16.92-35.4-25.862-55.063-25.862H927.765v.056H845.181c-39.8,0-75.406-36.821-90.717-93.807l-.029-.112L728.88,516.7v4.015Z"
					transform="translate(-731.88 216.677)"
				/>
				<rect className="d-products" width={1923} height={1134.375} />
				<text
					className="e-products"
					transform="translate(356.613 67.572)">
					<tspan x={28.487} y={100} xmlSpace="preserve">
						{' SOLUCIONES EN SALUD'}
					</tspan>
				</text>
				<a href="/productos/medicamentos">
					<g
						className="aa-products"
						transform="matrix(1, 0, 0, 1, 0, 0)"
						>
						<path
							className="f-products"
							d="M244.4,199.092H13.943A13.688,13.688,0,0,1,0,185.673V13.417A13.689,13.689,0,0,1,13.943,0H281.084c8.826,0,15.431,7.792,13.659,16.113L258.06,188.368A13.824,13.824,0,0,1,244.4,199.092"
							transform="translate(182.35 243.47)"
						/>
					</g>
					<text
						className="g-products"
						transform="translate(229 483.031)">
						<tspan x={0} y={0}>
							{'Medicamentos'}
						</tspan>
					</text>
				</a>

				<a href="/productos/dispositivos-medico-quirurgicos">
        <g className="z-products" transform="matrix(1, 0, 0, 1, 0, 0)">
					<path
						className="h-products"
						d="M244.4,449.334H13.943A13.688,13.688,0,0,0,0,462.752V635.008a13.689,13.689,0,0,0,13.943,13.418H281.084c8.826,0,15.431-7.792,13.659-16.113L258.06,460.058A13.824,13.824,0,0,0,244.4,449.334"
						transform="translate(182.35 174.01)"
					/>
				</g>
				<text
					className="g-products"
					transform="translate(329.864 862.902)">
					<tspan x={-71.928} y={0}>
						{'Dispositivos'}
					</tspan>
					<tspan x={-116.52} y={28}>
						{'m\xE9dico-quir\xFArgicos'}
					</tspan>
				</text>
        </a>
				<a href="/productos/equipos-medicos">
        <g className="y-products" transform="matrix(1, 0, 0, 1, 0, 0)">
					<path
						className="i-products"
						d="M687.1,0H978.713c8.386,0,14.87,8.35,13.813,17.788L962.005,186.007c-.884,7.892-6.8,13.807-13.813,13.807H718.572c-6.992,0-12.9-5.881-13.807-13.746L673.3,17.849C672.205,8.39,678.7,0,687.1,0"
						transform="translate(130.37 243.47)"
					/>
				</g>
				<text className="g-products" transform="translate(863 483.031)">
					<tspan x={0} y={0}>
						{'Equipos m\xE9dicos'}
					</tspan>
				</text>
        </a>
				<a href="/productos/instrumental-y-estirilizacion">
        <g className="x-products" transform="matrix(1, 0, 0, 1, 0, 0)">
					<path
						className="j-products"
						d="M978.712,648.3H687.1c-8.386,0-14.87-8.35-13.813-17.788l30.521-168.219c.884-7.892,6.8-13.807,13.813-13.807h229.62c6.992,0,12.9,5.881,13.807,13.746l31.468,168.219c1.093,9.459-5.4,17.849-13.806,17.849"
						transform="translate(130.37 174.14)"
					/>
				</g>
				<text
					className="g-products"
					transform="translate(792.773 859.437)">
					<tspan x={0} y={0}>
						{'Instrumental y esterilizaci\xF3n'}
					</tspan>
				</text>
        </a>
				<a href="/productos/logistica-hospitalaria">
        <g className="w-products" transform="matrix(1, 0, 0, 1, 0, 0)">
					<path
						className="k-products"
						d="M1423.459,199.092h230.458a13.688,13.688,0,0,0,13.942-13.418V13.417A13.689,13.689,0,0,0,1653.917,0H1386.776c-8.826,0-15.431,7.792-13.659,16.113L1409.8,188.368a13.824,13.824,0,0,0,13.659,10.724"
						transform="translate(76.34 243.47)"
					/>
				</g>
				<text
					className="g-products"
					transform="translate(1489 483.031)">
					<tspan x={0} y={0}>
						{'Log\xEDstica hospitalaria'}
					</tspan>
				</text>
        </a>
				<a href="/productos/neurointervencionismo">
        <g className="v-products" transform="matrix(1, 0, 0, 1, 0, 0)">
					<path
						className="l-products"
						d="M1423.459,449.334h230.458a13.688,13.688,0,0,1,13.942,13.418V635.008a13.689,13.689,0,0,1-13.942,13.418H1386.776c-8.826,0-15.431-7.792-13.659-16.113L1409.8,460.058a13.824,13.824,0,0,1,13.659-10.724"
						transform="translate(76.34 174.01)"
					/>
				</g>
				<text
					className="g-products"
					transform="translate(1467.2 859.437)">
					<tspan x={0} y={0}>
						{'Neurointervencionismo'}
					</tspan>
				</text>
        </a>
				<a href="/productos/cirugia-robotica">
        <g className="u-products" transform="matrix(1, 0, 0, 1, 0, 0)">
					<path
						className="m-products"
						d="M635.5,253.383H343.9c-8.386,0-14.87-8.349-13.813-17.788L360.6,67.376c.884-7.892,6.8-13.807,13.813-13.807H604.036c6.992,0,12.9,5.881,13.807,13.746l31.468,168.219c1.093,9.459-5.4,17.849-13.806,17.849"
						transform="translate(156.87 235.24)"
					/>
				</g>
				<text className="g-products" transform="translate(548 526.031)">
					<tspan x={0} y={0}>
						{'Cirug\xEDa rob\xF3tica'}
					</tspan>
				</text>
        </a>
				<a href="/productos/genomica-y-genetica">
        <g className="t-products" transform="matrix(1, 0, 0, 1, 0, 0)">
					<path
						className="n-products"
						d="M343.9,394.913H635.506c8.386,0,14.87,8.349,13.813,17.788L618.8,580.92c-.884,7.892-6.8,13.807-13.813,13.807H375.365c-6.992,0-12.9-5.881-13.807-13.746L330.091,412.762c-1.093-9.459,5.4-17.849,13.806-17.849"
						transform="translate(156.87 182.38)"
					/>
				</g>
				<text className="g-products" transform="translate(519 814.519)">
					<tspan x={0} y={0}>
						{'Gen\xF3mica y gen\xE9tica'}
					</tspan>
				</text>
        </a>
				<a href="/productos/ortopedia">
        <g className="s-products" transform="matrix(1, 0, 0, 1, 0, 0)">
					<path
						className="o-products"
						d="M1321.92,253.383H1030.311c-8.386,0-14.87-8.349-13.813-17.788l30.521-168.219c.884-7.892,6.8-13.807,13.813-13.807h229.619c6.991,0,12.9,5.881,13.807,13.746l31.468,168.219c1.092,9.459-5.4,17.849-13.806,17.849"
						transform="translate(103.62 235.24)"
					/>
				</g>
				<text
					className="g-products"
					transform="translate(1284 527.031)">
					<tspan x={-61.824} y={0}>
						{'Ortopedia'}
					</tspan>
				</text>
        </a>
				<a href="/productos/cuidado-avanzado-de-heridas">
        <g className="r-products" transform="matrix(1, 0, 0, 1, 0, 0)">
					<path
						className="p-products"
						d="M1030.311,394.913H1321.92c8.386,0,14.87,8.349,13.813,17.788L1305.212,580.92c-.884,7.892-6.8,13.807-13.813,13.807h-229.62c-6.991,0-12.9-5.881-13.807-13.746L1016.5,412.762c-1.093-9.459,5.4-17.849,13.806-17.849"
						transform="translate(103.86 182.38)"
					/>
				</g>
				<text
					className="g-products"
					transform="translate(1285 815.437)">
					<tspan x={-111.852} y={0}>
						{'Cuidado avanzado'}
					</tspan>
					<tspan x={-62.784} y={26}>
						{'de heridas'}
					</tspan>
				</text>
        </a>
			</svg>
		</div>
	)
}

export default Productos
