import React from 'react'

const SubmenuWorkUs: React.FC = () => {
	return (
		<svg viewBox="0 0 1930 360" className="absolute" style={{ zIndex: 1 }}>
			<defs>
				<style>
					{
						'.a-submenu-w{fill:none;}.b-submenu-w{fill:#001f5f;}.c-submenu-w{clip-path:url(#a-submenu-w);}.d-submenu-w,.h-submenu-w,.i-submenu-w{fill:#fff;}.e-submenu-w{fill:#e7002a;}.f-submenu-w{fill:url(#b-submenu-w);}.g-submenu-w{opacity:0.8;fill:url(#e-submenu-w);}.h-submenu-w{font-size:42px;font-family:"Kiona-Bold";font-weight:700;}.i-submenu-w{font-size:24px;font-family:Silka-Regular, Silka;}.j-submenu-w{filter:url(#f-submenu-w);mix-blend-mode:multiply;isolation:isolate;}.k-submenu-w{filter:url(#c-submenu-w);}'
					}
				</style>
				<clipPath id="a-submenu-w">
					<rect
						className="a-submenu-w"
						width={1427.387}
						height={206.155}
					/>
				</clipPath>
				<pattern
					id="b-submenu-w"
					width={1}
					height={1}
					patternTransform="matrix(-1, 0, 0, 1, 1159.222, 0)"
					viewBox="44.716 30.502 526.825 301">
					<image
						preserveAspectRatio="xMidYMid slice"
						width={579.611}
						height={386.436}
						xlinkHref="/images/navbar/trabaja-navbar.jpg"
					/>
				</pattern>
				<filter
					id="c"
					x={0}
					y={0}
					width={597.611}
					height={349.159}
					filterUnits="userSpaceOnUse">
					<feOffset dy={3} />
					<feGaussianBlur stdDeviation={3} result="d" />
					<feFlood floodOpacity={0.502} />
					<feComposite operator="in" in2="d" />
					<feComposite in="SourceGraphic" />
				</filter>
				<linearGradient
					id="e-submenu-w"
					x1={0.5}
					x2={0.5}
					y2={1}
					gradientUnits="objectBoundingBox">
					<stop offset={0} stopColor="#001f5f" stopOpacity={0} />
					<stop offset={1} stopColor="#001f5f" />
				</linearGradient>
				<filter
					id="f-submenu-w"
					x={0}
					y={0}
					width={597.611}
					height={349.159}
					filterUnits="userSpaceOnUse">
					<feOffset dy={3} />
					<feGaussianBlur stdDeviation={3} result="g" />
					<feFlood floodOpacity={0.502} />
					<feComposite operator="in" in2="g" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g>
				<g transform="translate(0 -104)">
					<g transform="translate(492.612 -22.409)">
						<rect
							className="b-submenu-w"
							width={1427.387}
							height={206.155}
							transform="translate(0 126.409)"
						/>
						<g transform="translate(0 126.409)">
							<g className="c-submenu-w">
								<path
									className="d-submenu-w"
									d="M1188.983,343.709l-.471-.113,36.034-49.477c3.884-5.333,10.376-9.844,18.777-13.049a80.187,80.187,0,0,1,28.27-4.9h161.04v.292h-161.04c-20.433,0-38.714,6.974-46.575,17.767Z"
									transform="translate(-5.245 -137.554)"
								/>
								<path
									className="d-submenu-w"
									d="M369.429,0l.638.247L321.252,109.14a65.276,65.276,0,0,1-25.436,28.719,72.593,72.593,0,0,1-38.3,10.779H39.353V148H257.516c27.681,0,52.448-15.349,63.1-39.1Z"
									transform="translate(-0.174 -117.002)"
								/>
								<path
									className="e-submenu-w"
									d="M1442.394,155.049l-55.915,116.287c-9.024,18.764-30.01,30.889-53.468,30.889h-28.562v0H1169.46A64.9,64.9,0,0,0,1137,310.736a52.919,52.919,0,0,0-21.555,22.686l.541.2c9.025-18.764,30.012-30.889,53.47-30.889h28.562v0h134.99a64.9,64.9,0,0,0,32.455-8.515,52.926,52.926,0,0,0,21.556-22.686l55.913-116.287Z"
									transform="translate(-2.564 -128.54)"
								/>
							</g>
						</g>
					</g>
					<path
						className="b-submenu-w"
						d="M367.709,339.246H0V0H586.714l-62.14,206.671a173.939,173.939,0,0,1-15.633,36.562c-36.385,63.36-89.124,96.013-141.232,96.013"
						transform="translate(0 104)"
					/>
					<a href="/trabaja-con-nosotros">
						<g
							className="k-submenu-w"
							transform="matrix(1, 0, 0, 1, -9, 98)">
							<path
								className="f-submenu-w"
								d="M366,457.568H0V126.409H579.611L519,328.153a169.849,169.849,0,0,1-15.248,35.69C468.267,425.694,416.828,457.568,366,457.568"
								transform="translate(9 -120.41)"
							/>
						</g>
						<g
							className="j-submenu-w"
							transform="matrix(1, 0, 0, 1, -9, 98)">
							<path
								className="g-submenu-w"
								d="M366,457.568H0V126.409H579.611L519,328.153a169.849,169.849,0,0,1-15.248,35.69C468.267,425.694,416.828,457.568,366,457.568"
								transform="translate(9 -120.41)"
							/>
						</g>
						<text
							className="h-submenu-w"
							transform="translate(139 359)">
							<tspan x={0} y={0}>
								{'TRABAJE CON'}
							</tspan>
							<tspan x={0} y={37}>
								{'NOSOTROS'}
							</tspan>
						</text>
					</a>
				</g>
				<a href="/trabaja-con-nosotros">
					<text
						className="i-submenu-w"
						transform="translate(692 116)">
						<tspan x={0} y={23}>
							{
								'Sea parte de nuestro talentoso y comprometido equipo.'
							}
						</tspan>
					</text>
					<text className="h-submenu-w" transform="translate(692 60)">
						<tspan x={0} y={42}>
							{'Encuentre oportunidades de carrera. \u200B'}
						</tspan>
					</text>
				</a>
			</g>
		</svg>
	)
}

export default SubmenuWorkUs
